<template>
    <b-form @submit.prevent="onSubmit">
        <b-row class="m-0">
            <b-col md="4" sm="12" class="pl-3">
                <b-form-group id="input-group-refreshable"
                              label="Refreshable"
                              label-for="input-refreshable">
                    <b-form-checkbox id="input-refreshable"
                                     v-model="settings.refreshable"
                                     :disabled="isSubmitting"
                                     @change="emitChanges"
                                     aria-describedby="input-refreshable-help-block">
                    </b-form-checkbox>
                </b-form-group>
            </b-col>

            <b-col md="4" sm="12" class="pl-0">
                <b-form-group id="input-group-clickableRows"
                              label="Clickable Rows"
                              label-for="input-clickableRows">
                    <b-form-checkbox id="input-clickableRows"
                                     v-model="settings.clickableRows"
                                     :disabled="isSubmitting"
                                     @change="emitChanges"
                                     aria-describedby="input-clickableRows-help-block">
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>

        <!-- Fields -->
        <h2>Fields</h2>
        <div v-for="(field, index) in form.fields"
             :key="index"
             class="field-group position-relative pointer p-1"
             draggable="true"
             @dragstart="onDragStart(index)"
             @dragover.prevent="onDragOver(index)"
             @drop="onDrop(index)">
            <b-row>
                <b-col cols="1" class="d-flex align-items-center">
                    <font-awesome-icon class="d-none d-sm-block text-secondary grabbable ml-3"
                                       title="Dra för att ändra ordning på fält"
                                       icon="grip-vertical"
                                       size="lg" />
                </b-col>
                <b-col cols="3">
                    <b-input-group size="md">
                        <b-input-group-prepend title="key">
                            <span class="input-group-text">
                                <font-awesome-icon class="d-none d-sm-block text-secondary"
                                                   icon="key"
                                                   size="sm" />
                            </span>
                        </b-input-group-prepend>
                        <b-form-input :id="'input-field-key-' + index"
                                      title="Key"
                                      v-model="field.key"
                                      type="text"
                                      placeholder="Field Key"
                                      :disabled="isSubmitting"
                                      @input="emitChanges"
                                      autocomplete="off"
                                      required />
                    </b-input-group>
                </b-col>
                <b-col cols="">
                    <b-input-group size="md">
                        <b-input-group-prepend title="label">
                            <span class="input-group-text">
                                <font-awesome-icon class="d-none d-sm-block text-secondary"
                                                   icon="tag"
                                                   size="sm" />
                            </span>
                        </b-input-group-prepend>
                        <b-form-input :id="'input-field-label-' + index"
                                      title="Label"
                                      v-model="field.label"
                                      type="text"
                                      placeholder="Field label"
                                      :disabled="isSubmitting"
                                      @input="emitChanges"
                                      autocomplete="off"
                                      required />
                    </b-input-group>
                </b-col>
                <b-col cols="2" class="pt-2">
                    <b-form-group id="input-group-sortable" class="mb-1">
                        <div class="d-flex align-items-center">
                            <b-form-checkbox :id="'input-sortable-' + index"
                                             v-model="field.sortable"
                                             :disabled="isSubmitting"
                                             @input="emitChanges"
                                             aria-describedby="input-sortable-help-block">
                            </b-form-checkbox>
                            <label for="'input-sortable-' + index" class="mb-0 ml-2">
                                Sortable
                            </label>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="1" md="2">
                    <b-form-select :id="'input-sortDirection-' + index"
                                   v-model="field.sortDirection"
                                   :options="sortDirectionOptions"
                                   :disabled="isSubmitting"
                                   @input="emitChanges"
                                   required />
                </b-col>
                <b-col cols="1"  class="d-flex align-items-center justify-content-center pr-4 pl-1">
                    <font-awesome-icon v-if="index !== 0"
                                       class="redhover"
                                       icon="times"
                                       size="lg"
                                       role="button"
                                       @click="removeField(index)" />
                </b-col>
            </b-row>
        </div>

        <!-- Add More Fields Button -->
        <div>
            <p class="mb-0">Lägg till fler fields</p>
            <font-awesome-icon class="d-none d-sm-inline text-secondary"
                               icon="plus-square"
                               size="lg"
                               role="button"
                               @click="addField" />
        </div>
    </b-form>
</template>

<script>

    export default {
        name: 'SettingsTableWidget',

        props: {
            settings: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },
        emits: ["update:settings"],
        data() {
            return {
                form: {
                    trackRowBy: 'id',
                    clickableRows: false,
                    refreshable: false,
                    fields: [
                        {
                            key: '',
                            label: '',
                            sortable: true,
                            sortDirection: 'desc',
                        },
                    ],
                },
                sortDirectionOptions: [
                    { value: 'desc', text: 'Desc' },
                    { value: 'asc', text: 'Asc' },

                ],
                isSubmitting: false,
                draggedIndex: null,
                dragOverIndex: null,
            };
        },
        async updated() {
            // sync form.fields with settings.fields only if they differ
            if (JSON.stringify(this.form.fields) !== JSON.stringify(this.settings.fields)) {
                this.form.fields = JSON.parse(JSON.stringify(this.settings.fields));
            }
        },
        async mounted() {
            this.form.fields = JSON.parse(JSON.stringify(this.settings.fields));
            // Initiate tempColors based on fields
        },
        methods: {
            emitChanges() {
                const fieldsWithoutTemp = this.form.fields.map((field) => {
                    const { key, label, sortable, sortDirection } = field;
                    return { key, label, sortable, sortDirection };
                });

                const settings = {
                    trackRowBy: 'id',
                    refreshable: this.settings.refreshable,
                    clickableRows: this.settings.clickableRows,
                    fields: fieldsWithoutTemp,
                };

                this.$emit('update:settings', settings);
            },
            addField() {
                this.form.fields.push({
                    key: '',
                    label: '',
                    sortable: true,
                    sortDirection: 'desc',
                });
                this.emitChanges();
            },
            removeField(index) {
                this.form.fields.splice(index, 1);
                this.emitChanges();
            },
            onDragStart(index) {
                this.draggedIndex = index;
            },
            onDragOver(index) {
                this.dragOverIndex = index;
            },
            onDrop(index) {
                if (this.draggedIndex !== null && this.dragOverIndex !== null) {
                    const draggedField = this.form.fields[this.draggedIndex];
                    this.form.fields.splice(this.draggedIndex, 1); // Remove from old place
                    this.form.fields.splice(index, 0, draggedField); // Add to new place
                    this.emitChanges();
                }
                this.draggedIndex = null; // reset dragstatus
            },
        }
    }
</script>


<style scoped>
    .field-group {
        border: 1px solid #ccc;
        border-radius: 8px;
        margin-bottom: 15px;
        background-color: #f9f9f9;
        position: relative;
        transition: background-color 0.3s;
    }

    .remove-field-button {
        position: absolute;
        bottom: 0px;
        right: 0px;
        cursor: pointer;
    }

    .field-group:hover {
        background-color: #f1f1f1;
    }

    .pointer {
        cursor: pointer;
    }

    .grabbable {
        cursor: grab;
    }
    .redhover {
        color: gray;
    }

        .redhover:hover {
            color: red !important;
            cursor: pointer;
        }
</style>