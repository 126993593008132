<template>
    <b-modal ref="previewModal" size="lg" title="Förhandsvisning av Widget" hide-footer @hide="$emit('close')">
        <component v-if="customerId"
                   :is="form.componentName"
                   :widget="form"
                   :customerId="customerId"
                   :startDate="startDate"
                   :endDate="endDate" />
    </b-modal>
</template>

<script>
    import ChartWidget from '@/components/widgets/ChartWidget.vue';
    import TableWidget from '@/components/widgets/TableWidget.vue';

    export default {
        components: {
            ChartWidget,
            TableWidget,
        },
        props: {
            form: {
                type: Object,
                required: true,
            },
            
            customerId: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                startDate: this.getOneYearAgo(),
                endDate: this.getToday(),
            }
        },

        mounted() {
            this.$refs.previewModal.show();
        },

        methods: {
            getToday() {
                const today = new Date();
                return today.toISOString().substr(0, 10);
            },
            getOneYearAgo() {
                const today = new Date();
                today.setFullYear(today.getFullYear() - 1);
                return today.toISOString().substr(0, 10);
            }

        }
    };
</script>
