<template>
    <b-form @submit.prevent="onSubmit">
        <!-- Options -->
        <b-row class="m-0">
            <b-col md="2" sm="12" class="pl-0">
                <b-form-group id="input-group-refreshable"
                              label="Refreshable"
                              label-for="input-refreshable">
                    <b-form-checkbox id="input-refreshable"
                                     v-model="settings.refreshable"
                                     :disabled="isSubmitting"
                                     @change="emitChanges"
                                     aria-describedby="input-refreshable-help-block">
                    </b-form-checkbox>
                </b-form-group>
            </b-col>

            <b-col md="5" sm="12" class="pl-0">
                <b-form-group id="input-group-label"
                              label="Label"
                              label-for="input-label">
                    <b-form-input id="input-label"
                                  v-model="settings.label"
                                  type="text"
                                  placeholder="Label"
                                  :disabled="isSubmitting"
                                  @input="emitChanges"
                                  autocomplete="off"
                                  aria-describedby="input-label-help-block"
                                  required />
                </b-form-group>
            </b-col>
            <b-col md="5" sm="12" class="pl-0">
                <b-form-group v-if="form.fields[0].type === 'pie'"
                              id="input-group-useDataColor"
                              label="Use Data Color"
                              label-for="input-useDataColor">
                    <b-form-input id="input-useDataColor"
                                  v-model="settings.useDataColor"
                                  type="text"
                                  placeholder=""
                                  :disabled="isSubmitting"
                                  @input="emitChanges"
                                  autocomplete="off"
                                  aria-describedby="input-useDataColor-help-block" />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="m-0">
            <b-col md="4" sm="12" class="pl-0">
                <b-form-group id="input-group-useDateFilter"
                              label="Use Date Filter"
                              label-for="input-useDateFilter">
                    <b-form-checkbox id="input-useDateFilter"
                                     v-model="settings.useDateFilter"
                                     :disabled="isSubmitting"
                                     @change="emitChanges"
                                     aria-describedby="input-useDateFilter-help-block">
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col v-if="settings.options" md="4" sm="12" class="pl-0">
                <b-form-group id="input-group-responsive"
                              label="Responsive"
                              label-for="input-responsive">
                    <b-form-checkbox id="input-responsive"
                                     v-model="settings.options.responsive"
                                     :disabled="isSubmitting"
                                     @change="emitChanges"
                                     aria-describedby="input-responsive-help-block">
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
            <b-col v-if="settings.options" md="4" sm="12">
                <b-form-group id="input-group-maintainAspectRatio"
                              label="Maintain Aspect Ratio"
                              label-for="input-maintainAspectRatio">
                    <b-form-checkbox id="input-maintainAspectRatio"
                                     v-model="settings.options.maintainAspectRatio"
                                     :disabled="isSubmitting"
                                     @change="emitChanges"
                                     aria-describedby="input-maintainAspectRatio-help-block">
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>

        <!-- Fields -->
        <h2>Fields</h2>
        <div v-for="(field, index) in form.fields"
             :key="index"
             class="field-group p-2 mb-1 position-relative pointer"
             draggable="true"
             @dragstart="onDragStart(index)"
             @dragover.prevent="onDragOver(index)"
             @drop="onDrop(index)">
            <b-row>
                <b-col cols="1" class="d-flex align-items-center pl-4">
                    <font-awesome-icon class="d-none d-sm-block text-secondary grabbable"
                                       title="Dra för att ändra ordning på fält"
                                       icon="grip-vertical"
                                       size="lg" />
                </b-col>
                <b-col cols="3">
                    <b-input-group size="md">
                        <b-input-group-prepend title="key">
                            <span class="input-group-text">
                                <font-awesome-icon class="d-none d-sm-block text-secondary"
                                                   icon="key"
                                                   size="sm" />
                            </span>
                        </b-input-group-prepend>
                        <b-form-input :id="'input-field-key-' + index"
                                      title="Key"
                                      v-model="field.key"
                                      type="text"
                                      placeholder="Field Key"
                                      :disabled="isSubmitting"
                                      @input="emitChanges"
                                      autocomplete="off"
                                      required />
                    </b-input-group>
                </b-col>
                <b-col cols="3">
                    <b-input-group size="md">
                        <b-input-group-prepend title="label">
                            <span class="input-group-text">
                                <font-awesome-icon class="d-none d-sm-block text-secondary"
                                                   icon="tag"
                                                   size="sm" />
                            </span>
                        </b-input-group-prepend>
                        <b-form-input :id="'input-field-label-' + index"
                                      title="label"
                                      v-model="field.label"
                                      type="text"
                                      placeholder="Field label"
                                      :disabled="isSubmitting"
                                      @input="emitChanges"
                                      autocomplete="off"
                                      required />
                    </b-input-group>
                </b-col>
                <b-col cols="3">
                    <b-input-group size="m">
                        <b-input-group-prepend>
                            <span class="input-group-text">
                                <font-awesome-icon class="d-none d-sm-block text-secondary"
                                                   icon="plus-square"
                                                   size="sm" />
                            </span>
                        </b-input-group-prepend>
                        <b-form-select :id="'input-type-' + index"
                                       v-model="field.type"
                                       :options="getTypeOptions(index)"
                                       :disabled="isSubmitting"
                                       @input="emitChanges"
                                       @change="handleTypeChange(index)"
                                       required />
                    </b-input-group>
                </b-col>
                <b-col cols="1" class="pl-0">
                    <b-form-checkbox v-if="!['pie', 'bar'].includes(field.type)"
                                     :id="'input-fill-' + index"
                                     v-model="field.fill"
                                     :disabled="isSubmitting"
                                     @input="emitChanges"
                                     class="mt-1">
                        Fill
                    </b-form-checkbox>
                </b-col>
                <b-col cols="1" v-if="index !== 0" class="d-flex align-items-center justify-content-center pr-3 pl-1">
                    <font-awesome-icon class="redhover"
                                       icon="times"
                                       size="lg"
                                       role="button"
                                       @click="removeField(index)" />
                </b-col>
            </b-row>
            <b-row v-if="!settings.useDataColor">
                <b-col cols="1">

                </b-col>
                <b-col cols="3" class="mt-2 pl-3">
                    <WidgetColorPicker v-if="!settings.useDataColor"
                                       :modelValue="tempColors[index] || '#000000'"
                                       @update:modelValue="(value) => updateTempColor(index, value)" />
                </b-col>
                <b-col cols="1" class="mt-3 p-0">
                    <font-awesome-icon v-if="!settings.useDataColor"
                                       class="d-none d-sm-inline text-secondary"
                                       icon="plus-square"
                                       size="lg"
                                       role="button"
                                       @click="addColor(index)" />
                </b-col>
                <b-col cols="7" class="p-0" v-if="!settings.useDataColor">
                    <div class="color-boxes" v-if="Array.isArray(field.color) ? field.color.length > 0 : !!field.color">
                        <div v-for="(color, colorIndex) in Array.isArray(field.color) ? field.color : [field.color]"
                             :key="'color-' + index + '-' + colorIndex"
                             class="color-box position-relative">
                            <font-awesome-icon class="position-absolute text-danger"
                                               icon="times"
                                               size="sm"
                                               style="top: -5px; right: -5px; cursor: pointer;"
                                               @click="removeColor(index, colorIndex)" />
                            <div :style="{ backgroundColor: color }" class="color-preview"></div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <!-- Add More Fields Button -->
        <div v-if="form.fields[0].type !== 'pie'">
            <p class="mb-0">Lägg till fler fields</p>
            <font-awesome-icon class="d-none d-sm-inline text-secondary"
                               icon="plus-square"
                               size="lg"
                               role="button"
                               @click="addField" />
        </div>
    </b-form>
</template>

<script>
    import WidgetColorPicker from "./WidgetColorPicker.vue";

    export default {
        name: 'SettingsChartWidget',
        components: {
            WidgetColorPicker,
        },
        props: {
            settings: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },
        emits: ["update:settings"],
        data() {
            return {
                form: {
                    refreshable: false,
                    label: '',
                    useDateFilter: false,
                    responsive: false,
                    maintainAspectRatio: false,
                    fields: [
                        {
                            key: '',
                            label: '',
                            type: 'pie',
                            fill: false,
                            color: [],
                        },
                    ],
                },
                tempColors: [],
                typeOptions: [
                    { value: 'pie', text: 'Pie' },
                    { value: 'line', text: 'Line' },
                    { value: 'bar', text: 'Bar' },
                ],
                isSubmitting: false,
            };
        },
        async mounted() {
            this.form.fields = JSON.parse(JSON.stringify(this.settings.fields));
            // Initiate tempColors based on fields
            this.tempColors = this.form.fields.map(() => '#000000');
        },
        async updated() {
            // sync form.fields with settings.fields only if they differ
            if (JSON.stringify(this.form.fields) !== JSON.stringify(this.settings.fields)) {
                this.form.fields = JSON.parse(JSON.stringify(this.settings.fields));
            }
        },
        methods: {
            emitChanges() {
                const fieldsWithoutTemp = this.form.fields.map((field) => {
                    const { key, label, type, fill, color } = field;
                    return {
                        key,
                        label,
                        type,
                        fill,
                        color: this.settings.useDataColor ? [] : color
                    };
                });

                const settings = {
                    refreshable: this.settings.refreshable,
                    label: this.settings.label,
                    useDateFilter: this.settings.useDateFilter,
                    useDataColor: this.settings.useDataColor,
                    options: this.settings.options,
                    fields: fieldsWithoutTemp,
                };

                this.$emit('update:settings', settings);
            },
            getTypeOptions(index) {
                return index === 0
                    ? this.typeOptions
                    : this.typeOptions.filter((option) => option.value !== 'pie');
            },
            addField() {
                this.form.fields.push({
                    key: '',
                    label: '',
                    type: 'line',
                    fill: false,
                    color: '#000000',
                });
                this.emitChanges();
            },
            removeField(index) {
                this.form.fields.splice(index, 1);
                this.emitChanges();
            },
            addColor(index) {
                const field = this.form.fields[index];
                const tempColor = this.tempColors[index];
                if (!Array.isArray(field.color)) {
                    field.color = [];
                }
                if (field.type === 'pie') {
                    if (!field.color.includes(tempColor)) {
                        field.color.push(tempColor);
                    }
                } else {
                    field.color = tempColor;
                }
                this.emitChanges();
            },
            updateColor(index, value) {
                const field = this.form.fields[index];
                if (field.type === 'pie') {
                    if (!Array.isArray(field.color)) field.color = [];
                    if (!field.color.includes(value)) field.color.push(value);
                } else {
                    field.color = value;
                }
                this.emitChanges();
            },
            updateTempColor(index, value) {
                this.$set(this.tempColors, index, value);
            },

            removeColor(fieldIndex, colorIndex) {
                const field = this.form.fields[fieldIndex];
                if (Array.isArray(field.color)) {
                    field.color.splice(colorIndex, 1);
                } else {
                    field.color = '#000000';
                }
                this.emitChanges();
            },
            onTypeChange(index) {
                this.form.fields[index].colours = [];
                this.emitChanges();
            },
            handleTypeChange(index) {
                const field = this.form.fields[index];
                if (index === 0 && field.type === 'pie') {
                    this.form.fields = [field];
                }
                field.color = field.type === 'pie' ? [] : '#000000';
                this.tempColors[index] = '#000000'; // reset tempoColors
                this.emitChanges();
            },
            onDragStart(index) {
                this.draggedIndex = index;
            },
            onDragOver(index) {
                this.dragOverIndex = index;
            },
            onDrop(index) {
                if (this.draggedIndex !== null && this.dragOverIndex !== null) {
                    const draggedField = this.form.fields[this.draggedIndex];
                    this.form.fields.splice(this.draggedIndex, 1); // Remove from old place
                    this.form.fields.splice(index, 0, draggedField); // Add to new place
                    this.emitChanges();
                }
                this.draggedIndex = null; // reset dragstatus
            },
        },

    };
</script>

<style scoped>
    .field-group {
        border: 1px solid #ccc;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 15px;
        background-color: #f9f9f9;
        position: relative;
    }

    .color-boxes {
        display: flex;
        gap: 10px;
        margin-top: 10px;
    }

    .color-box {
        width: 30px;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 4px;
        position: relative;
    }

    .color-preview {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    .remove-field-button {
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
    }

    .pointer {
        cursor: pointer;
    }

    .grabbable {
        cursor: grab;
    }

    .redhover {
        color: gray;
    }

        .redhover:hover {
            color: red !important;
            cursor: pointer;
        }
</style>