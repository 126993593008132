<template>
    <div>
        <label v-if="label" class="form-label" for="series-color">{{ label }}</label>
        <input type="color"
               v-model="colorValue"
               id="series-color"
               :list="hasOptions ? 'series-color-list' : null"
               class="form-control"
               :disabled="disabled"
               :readonly="readonly"
               @input="emitColorChange" />
        <datalist v-if="hasOptions" id="series-color-list">
            <option v-for="color in options" :key="color" :value="color">
                {{ color }}
            </option>
        </datalist>
    </div>
</template>

<script>
    export default {
        name: 'WidgetColorPicker',
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        modelValue: {
            type: String,
            required: true,
        },
        disabled: {
            type: [Boolean, String],
            default: false,
        },
        readonly: {
            type: [Boolean, String],
            default: false,
        },
    },
    data() {
        return {
            colorValue: this.modelValue
        };
    },
    computed: {
        hasOptions() {
            return this.options.length > 0;
        }
    },
    watch: {
        modelValue(newValue) {
            this.colorValue = newValue;
        }
    },
    methods: {
        emitColorChange(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
};
</script>
