<template>
    <div style="margin-bottom: 7rem !important" class="container">
        <h1 v-if="!id">{{ form.id ? 'Ändra' : 'Skapa' }} widgets</h1>
        <hr />
        <b-form @submit.prevent="onSubmit">
            <b-form-group id="input-group-dashboardId"
                          label="Dashboard"
                          label-for="input-dashboardId">
                <b-form-select id="input-dashboardId"
                               v-model="form.dashboardId"
                               @input="saveToLocalStorage"
                               :options="dashboardOptions"
                               type="text"
                               placeholder="Välj dashboard"
                               :disabled="isSubmitting"
                               autocomplete="off"
                               aria-describedby="input-dashboardId-help-block"
                               required />
                <b-form-text id="input-dashboardId-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('DashboardId')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-title" label="Titel" label-for="input-title">
                <b-form-input id="input-title"
                              v-model="form.title"
                              @input="saveToLocalStorage"
                              type="text"
                              placeholder="Ange titel"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-title-help-block"
                              required />
                <b-form-text id="input-title-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Title')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <!--Dropdown-->
            <b-form-group id="input-group-componentName" label="Komponentnamn" label-for="input-componentName">
                <b-form-select id="input-componentName"
                               v-model="form.componentName"
                               type="text"
                               @change="onComponentNameChange"
                               placeholder="Ange komponentnamn"
                               :options="componentNameOptions"
                               :disabled="isSubmitting"
                               autocomplete="off"
                               aria-describedby="input-componentName-help-block"
                               required />
                <b-form-text id="input-componentName-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('ComponentName')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <!--Dropdown-->
            <b-form-group id="input-group-cols" label="Kolumnstorlek" label-for="input-cols">
                <b-form-select id="input-cols"
                               v-model="form.cols"
                               @input="saveToLocalStorage"
                               type="text"
                               placeholder="Ange kolumnstorlek"
                               :options="colOptions"
                               :disabled="isSubmitting"
                               autocomplete="off"
                               aria-describedby="input-cols-help-block"
                               required />
                <b-form-text id="input-cols-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Cols')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-sortOrder" label="Sorteringsordning" label-for="input-sortOrder">
                <b-form-input id="input-sortOrder"
                              v-model="form.sortOrder"
                              @input="saveToLocalStorage"
                              type="number"
                              min="1"
                              max="2,147,483,647"
                              placeholder="Ange sorteringsordning"
                              :disabled="isSubmitting"
                              autocomplete="off"
                              aria-describedby="input-sortOrder-help-block"
                              required />
                <b-form-text id="input-sortOrder-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('SortOrder')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>

            <b-form-group id="input-group-sqlQuery" label="SQLQuery" label-for="input-sqlQuery">
                <b-form-textarea id="input-sqlQuery"
                                 v-model="form.sqlQuery"
                                 @input="saveToLocalStorage"
                                 type="text"
                                 placeholder="Ange SQLQuery"
                                 :disabled="isSubmitting"
                                 autocomplete="off"
                                 aria-describedby="input-sqlQuery-help-block"
                                 rows="10"
                                 required />
                <b-form-text id="input-sqlQuery-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('SQLQuery')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>
            <div>
                <b-card no-body>
                    <b-tabs card>
                        <b-tab title="Inputs" active>
                            <SettingsChartWidget v-if="form.componentName === 'ChartWidget' && form.settings.options"
                                                 :settings.sync="form.settings"
                                                 @update:settings="updateJsonSettings" />
                            <SettingsTableWidget v-if="form.componentName === 'TableWidget'"
                                                 :settings.sync="form.settings"
                                                 @update:settings="updateJsonSettings" />
                        </b-tab>
                        <b-tab title="JSON">
                            <b-form-group id="input-group-settings" label="Inställningar" label-for="input-settings">
                                <b-form-textarea id="input-settings"
                                                 v-model="jsonSettings"
                                                 type="text"
                                                 placeholder="Ange inställningar"
                                                 @input="parseJsonSettings"
                                                 :disabled="isSubmitting"
                                                 autocomplete="off"
                                                 aria-describedby="input-settings-help-block"
                                                 rows="10"
                                                 required />
                                <b-form-text id="input-settings-help-block">
                                    <ul class="text-danger mb-0">
                                        <li v-for="message in validationByKey('Settings')" :key="message">
                                            {{ message }}
                                        </li>
                                    </ul>
                                </b-form-text>
                            </b-form-group>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
            <b-row class="m-0">
                <b-col md="4" sm="12">
                    <b-form-group id="input-group-isActive"
                                  label="Aktiv"
                                  label-for="input-isActive">
                        <b-form-checkbox id="input-isActive"
                                         v-model="form.isActive"
                                         @input="saveToLocalStorage"
                                         :disabled="isSubmitting"
                                         aria-describedby="input-isActive-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('IsActive')" :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>

                <b-col md="4" sm="12">
                    <b-form-group id="input-group-isDynamic"
                                  label="Dynamisk"
                                  label-for="input-isDynamic">
                        <b-form-checkbox id="input-isDynamic"
                                         v-model="form.isDynamic"
                                         @input="saveToLocalStorage"
                                         :disabled="isSubmitting"
                                         aria-describedby="input-isDynamic-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('IsDynamic')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-alert :show="validationByKey('Other').length > 0" variant="danger">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Other')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-alert>

            <b-alert :show="successMessage.length > 0"
                     variant="success"
                     class="mb-0 mt-4">
                <h6 class="mb-0 text-center">
                    <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                </h6>
            </b-alert>
            <b-row>
                <b-col>
                    <b-button v-if="id !== 0" v-b-tooltip.hover
                              variant="info"
                              class="mt-4"
                              :class="{ 'btn-block': id }"
                              :disabled="isSubmitting"
                              @click="preview"
                              :title="isSubmitting ? 'Förhandsgranskar...' : ''">
                        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara & förhandsgranska
                    </b-button>
                </b-col>
                <b-col>
                    <b-button v-if="id !== 0" v-b-tooltip.hover
                              variant="secondary"
                              class="mt-4"
                              :class="{ 'btn-block': id }"
                              :disabled="isSubmitting"
                              @click="saveAsCopy"
                              :title="isSubmitting ? 'Sparar kopia...' : ''">
                        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara som kopia
                    </b-button>
                </b-col>
                <b-col>
                    <b-button v-if="!id" variant="danger"
                              class="mt-4"
                              @click="cancelForm">
                        Avbryt
                    </b-button>
                    <b-button v-b-tooltip.hover
                              variant="primary"
                              class="float-right mt-4"
                              :class="{ 'btn-block': id }"
                              :disabled="isSubmitting"
                              type="submit"
                              :title="isSubmitting ? 'Sparar...' : ''">
                        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
        <!-- Preview-komponenten -->
        <PreviewWidget v-if="isPreviewVisible"
                       :form="previewData"
                       :customerId="selectedCustomer.id || null"
                       @close="isPreviewVisible = false" />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { get, post } from '@/helpers/api';
    import SettingsChartWidget from '../../../../components/widgets/SettingsChartWidget.vue';
    import SettingsTableWidget from '../../../../components/widgets/SettingsTableWidget.vue';
    import PreviewWidget from '../../../../components/widgets/PreviewWidget.vue';

    export default {
        name: 'SaveWidget',
        components: {
            SettingsChartWidget,
            SettingsTableWidget,
            PreviewWidget
        },
        props: {
            id: Number,
        },
        data() {
            return {
                previewData: null,
                isPreviewVisible: false,
                loadedFromStorage: false,
                form: {
                    id: null,
                    dashboardId: null,
                    title: '',
                    componentName: 'ChartWidget',
                    cols: 4,
                    sortOrder: 999,
                    isActive: false,
                    isDynamic: true,
                    sqlQuery: '',
                    settings: this.getDefaultSettings('ChartWidget'), // Initial empty settings
                },
                dashboardOptions: [],
                componentNameOptions: [
                    { value: 'TableWidget', text: 'TableWidget' },
                    { value: 'ChartWidget', text: 'ChartWidget' },
                ],
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                colOptions: Array.from({ length: 12 }, (v, k) => k + 1).map((num) => ({
                    value: num,
                    text: num.toString(),
                })),
                jsonSettings: '',
            };
        },

        computed: {
            isEditMode() {
                return this.form.id > 0;
            },
            chartOptions() {
                return this.settings?.options || {};
            },
            ...mapState({
                selectedCustomer: (state) => state.user.selectedCustomer,
            }),
        },

        async mounted() {
            this.loadFromLocalStorage();
            this.getData();
            this.form.id = this.id || 0;

            if (this.isEditMode) {
                await get('Widget', `Get?id=${this.form.id}`)
                    .then((x) => {
                        this.form = x.data;

                        if (typeof this.form.settings === 'string') {
                            try {
                                this.form.settings = JSON.parse(this.form.settings);
                            } catch (error) {
                                console.error('Error parsing settings JSON:', error);
                                this.form.settings = {};
                            }
                        }
                        this.updateJsonSettings();
                    })
                    .catch((x) => {
                        alert(x.data);
                    });
            }
            if (this.form.settings) {
                this.updateJsonSettings();
            }
        },

        methods: {
            onComponentNameChange() {
                // Update form.settings with default settings based on the selected component name
                this.form.settings = this.getDefaultSettings(this.form.componentName);
                if (!this.form.settings.options) {
                    this.form.settings.options = {};
                }
                // Update the JSON to reflect the change
                this.updateJsonSettings();
            },
            getDefaultSettings(componentName) {
                const defaultSettings = {
                    ChartWidget: {
                        refreshable: false,
                        options: {
                            responsive: false,
                            maintainAspectRatio: false,
                        },
                        fields: [
                            {
                                key: '',
                                label: '',
                                type: 'pie',
                                fill: false,
                                color: '#000000',
                            },
                        ],
                    },
                    TableWidget: {
                        trackRowBy: 'id',
                        clickableRows: false,
                        refreshable: false,
                        fields: [
                            {
                                key: '',
                                label: '',
                                sortable: true,
                                sortDirection: 'desc',
                            },
                        ],
                    },
                };
                return defaultSettings[componentName] || { options: {} };
            },
            async onSubmit() {
                // Resets
                this.clearLocalStorage();
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;
                try {
                    const settingsCopy = JSON.parse(JSON.stringify(this.form.settings)); // Make a copy of settings
                    const payload = {
                        ...this.form,
                        settings: JSON.stringify(settingsCopy),
                    };
                    const response = await post('Widget', 'Save', payload);
                    if (response.data) {
                        if (!this.form.id && response.data.entityId) {
                            this.form.id = response.data.entityId;
                        }
                        this.successMessage = `${this.form.id ? 'Uppdaterade' : 'Skapade'} ${this.form.title} ${this.form.componentName}`;
                    }
                } catch (error) {
                    if (error.response?.data?.errors) {
                        this.validationErrors = error.response.data.errors;
                    } else {
                        this.validationErrors = [{ message: error.message }];
                    }
                } finally {
                    this.isSubmitting = false;

                    if (!this.validationErrors && this.successMessage) {
                        this.onCreated();
                    }
                }
            },
            onCreated() {
                this.hasSavedSuccessfullly = true;
            },

            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            resetForm() {
                this.form = {
                    id: null,
                    dashboardId: null,
                    title: '',
                    componentName: 'ChartWidget', 
                    cols: 4,                      
                    sortOrder: 999,               
                    isActive: false,
                    isDynamic: true,
                    sqlQuery: '',
                    settings: this.getDefaultSettings('ChartWidget'), // reset to defaultsettings
                };
            },
            async confirm() {
                this.$bvModal
                    .msgBoxConfirm('Förändringar har skett', {
                        title: 'Är du säker?',
                        size: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((confirmed) => {
                        return confirmed;
                    });
            },
            async getData() {
                await get('Dashboard', 'GetAll')
                    .then((x) => {
                        this.dashboards = x.data;
                        this.dashboardOptions = this.dashboards.map((dashboard) => ({
                            value: dashboard.id,
                            text: dashboard.name,
                        }));
                    })
                    .catch((x) => {
                        // error handling.
                    });
            },
            updateJsonSettings() {
                this.jsonSettings = JSON.stringify(this.form.settings, null, 2);
                this.saveToLocalStorage();
            },

            parseJsonSettings() {
                try {
                    this.form.settings = JSON.parse(this.jsonSettings);
                } catch (error) {
                    console.error('Ogiltig JSON:', error);
                    this.$bvToast.toast('JSON är ogiltig!', {
                        title: 'Fel',
                        variant: 'danger',
                        solid: true,
                    });
                }
            },
            saveAsCopy() {
                if (this.isSubmitting) return;
                this.isSubmitting = true;
                const copiedWidget = {
                    ...this.form,
                    id: 0,
                    title: `${this.form.title} (Kopia)`,
                    settings: JSON.stringify(this.form.settings),
                };
                post('Widget', 'Save', copiedWidget)
                    .then((response) => {
                        if (response.data && response.data.entityId) {
                            this.$bvToast.toast('Widget kopierad och sparad framgångsrikt!', {
                                title: 'Kopiering lyckades',
                                variant: 'success',
                                solid: true,
                            });
                            this.form.id = response.data.entityId;
                        }
                    })
                    .catch((error) => {
                        console.error('Kunde inte spara widgeten som kopia:', error);
                        this.$bvToast.toast('Kunde inte spara kopian. Försök igen.', {
                            title: 'Fel',
                            variant: 'danger',
                            solid: true,
                        });
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                    });
            },
            async preview() {
                await this.onSubmit();
                    this.previewData = JSON.parse(JSON.stringify(this.form)); // copy formdata
                    this.isPreviewVisible = true;
             
             


            },
            loadFromLocalStorage() {
                if (this.loadedFromStorage) return;
                const savedForm = localStorage.getItem('widgetForm');
                if (savedForm) {
                    try {
                        this.form = JSON.parse(savedForm);
                        this.loadedFromStorage = true; 
                    } catch (error) {
                        console.error('Error parsing saved form from localStorage:', error);
                    }
                }
            },
            saveToLocalStorage() {
                if (!this.form.id) { 
                    localStorage.setItem('widgetForm', JSON.stringify(this.form));
                }
            },
            clearLocalStorage() {
                localStorage.removeItem('widgetForm');
            },
            cancelForm() {
                this.clearLocalStorage(); 
                this.resetForm();         
                this.$bvToast.toast('Formuläret har återställts till standardvärden!', {
                    title: 'Avbrutet',
                    variant: 'info',
                    solid: true,
                });
            },
        },
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
